import Swal from "sweetalert2";
import "@sweetalert2/theme-dark/dark.css";

/**
 * Supported types are success, error
 *
 */
const supported = ["success", "error", "warning", "info", "question"];
const _check = (type) => {
  type = type.toLowerCase();
  if (supported.indexOf(type) === -1) {
    let error = new Error("Un Expected type, Expected " + supported.join(","));
    console.error(error);
    return false;
  }
  return true;
};
const AlertService = {
  alert: ({
    type = "success",
    title = "",
    message = "",
    timeout = 1500,
  } = {}) => {
    let stats = _check(type);
    if (!stats) return;
    const obj = {
      icon: type,
      title: title,
      showConfirmButton: true,
      timer: timeout,
      heightAuto: false,
      backdrop: false,
    };
    // if()
    if (message !== "" && title === "") obj.title = message;
    else if (message !== "") obj.text = message;
    return new Promise((resolve, reject) => {
      Swal.fire({ ...obj })
        .then(() => resolve(true))
        .catch(reject);
    });
  },
  confirm: ({
    title = "",
    message = "",
    okText = "Confirm",
    cancelText = "Cancel",
    allowEscapeKey = true,
    allowEnterKey = true
  }) => {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: title,
        icon: "info",
        html: message,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: okText,
        cancelButtonText: cancelText,
        heightAuto: false,
        allowEscapeKey: allowEscapeKey,
        allowEnterKey: allowEnterKey,
        allowOutsideClick: false,
        backdrop: false
      })
        .then((result) => {
          if (result.isConfirmed) resolve(true);
          else if (result.isDenied) resolve(false);
          else if (result.isDismissed) resolve(false);
        })
        .catch(reject);
    });
  },
};

const ToasterService = {
  success: ()=>{},
  error: ()=>{},
  warning: ()=>{},
  info: ()=>{},
};

// Remove question
supported.slice(0, supported.length - 1).forEach((type) => {
  ToasterService[type] = (message, timeout = 1500) => {
    const obj = {
      position: "top-end",
      toast: true,
      icon: type,
      title: message,
      showConfirmButton: false,
      timer: timeout,
    };
    return new Promise((resolve, reject) => {
      Swal.fire({ ...obj })
        .then(() => resolve(true))
        .catch(reject);
    });
  };
});

export { AlertService, ToasterService };
