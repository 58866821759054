// import { useState, useEffect } from "react";

// import { Link, useLocation } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { Logout } from "../../store/reducers/auth";
// import { NvaigationBar } from "../navigation/menu";
import { useLocation, useNavigate } from "react-router-dom";

const MenuItems = [
    {
        title: "Youtube Channel Details",
        key: "youtube-scrape",
        link: "video-sources/youtube-scrape",
    },
    {
        title: "Videos pending validation",
        key: "video-pending-val",
        link: "video-sources/video-pending-val",
    },
    {
        title: "Valid videos",
        key: "valid-videos",
        link: "video-sources/valid-videos",
    },
    {
        title: "Invalid videos",
        key: "invalid-videos",
        link: "video-sources/invalid-videos",
    }
];
function VideoSourceTabBar() {
    const location = useLocation()
    const navigate = useNavigate();
    return (
        <>
            <ul className="nav nav-tabs" data-bs-tabs="tabs">
                {MenuItems.map((menu) => {
                    return (
                        <li className="nav-item" key={menu.key}>
                            <button
                                className={
                                    location.pathname.replace("/", "") === menu.link
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                onClick={() => navigate(`/${menu.link}`)}
                            >
                                {menu.title}
                            </button>
                        </li>
                    );
                })}
            </ul>
        </>
    );
}

export { VideoSourceTabBar };
