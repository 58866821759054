import { Suspense } from "react";

import Main from "./Main";
import { Outlet } from "react-router-dom";
import { Preloader } from "./Preloader";

import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "./Header";

const MenuItems = [
    {
        title: "Dashboard",
        key: "dashboard",
        link: "home/dashboard",
    },
    {
        title: "Job Status",
        key: "job-status",
        link: "home/job-status",
    }
];
function TabBar() {
    const location = useLocation()
    const navigate = useNavigate();
    return (
        <>
            <ul className="nav nav-tabs" data-bs-tabs="tabs">
                {MenuItems.map((menu) => {
                    return (
                        <li className="nav-item" key={menu.key}>
                            <button
                                className={
                                    location.pathname.replace("/", "") === menu.link
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                onClick={() => navigate(`/${menu.link}`)}
                            >
                                {menu.title}
                            </button>
                        </li>
                    );
                })}
            </ul>
        </>
    );
}



const Home = () => {
    return (
        <>
            <Header/>
            <Main>
                <TabBar />
                <Suspense fallback={<Preloader suspencecallback={true} />}>
                    <Outlet />
                </Suspense>
            </Main>
        </>
    )
}

export { Home }