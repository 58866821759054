const ErrorTypes = {
  REQUEST_ERROR: "request_error",
  NETWORK_ERROR: "network_error",
  SERVER_ERROR: "server_error",
  AUTHENTICATION_ERROR: "authentication_error",
  TIMEOUT_ERROR: "timeout_error",
  UNKNOWN_ERROR: "unknown_error",
};

function handleApiError(error) {
  let errorType = ErrorTypes.UNKNOWN_ERROR;
  let errorMessage = "An unknown error occurred";
  let data = null;
  if (error.response) {
    switch (error.response.status) {
      case 400:
        errorType = ErrorTypes.REQUEST_ERROR;
        errorMessage = "Request error occurred";
        break;
      case 401:
        errorType = ErrorTypes.AUTHENTICATION_ERROR;
        errorMessage = "Authentication error occurred";
        break;
      case 403:
        errorType = ErrorTypes.AUTHENTICATION_ERROR;
        errorMessage = "Authentication error occurred";
        break;
      case 404:
        errorType = ErrorTypes.REQUEST_ERROR;
        errorMessage = "Resource not found";
        break;
      case 500:
        errorType = ErrorTypes.SERVER_ERROR;
        errorMessage = "Server error occurred";
        break;
      default:
        break;
    }
  } else if (error.request) {
    errorType = ErrorTypes.NETWORK_ERROR;
    errorMessage = "Network error occurred";
  } else if (error.code === "ECONNABORTED") {
    errorType = ErrorTypes.TIMEOUT_ERROR;
    errorMessage = "Request timed out";
  }
  if (error.response?.data) data = error.response?.data;
  return {
    type: errorType,
    message: errorMessage,
    data,
  };
}

export { handleApiError, ErrorTypes };
