import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import axios from "axios";
import { ErrorTypes, handleApiError } from "./services/axios-error-handler";
import { ACCESS_TOKEN } from "./services/auth.service";
import { SKIP_AUTH_ERROR_PATHS } from "./routes";
import { constants } from "./services/constants";
// const REACT_APP_API_URL = `https://artelize.in.ngrok.io/api/`;
// const REACT_APP_API_URL = `http://localhost:3002/`;
axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage[ACCESS_TOKEN];
    config.baseURL = process.env.REACT_APP_API_URL ;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (err) => {
    Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (res) => {
    let response = res.data;
    if(response.status === constants.ERROR){
      let err =new Error(JSON.stringify(response))
      throw err
    }
    return response;
  },
  (err) => {
    const error = handleApiError(err);
    if (error.type === ErrorTypes.AUTHENTICATION_ERROR) {
      if (SKIP_AUTH_ERROR_PATHS.indexOf(window.location.pathname) === -1) {
        localStorage.clear();
        window.location.href = "/auth/login";
      } else return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
