import axios from "axios";

export const ACCESS_TOKEN = "workerpwd";
export const LOGINED_USER = "workerusr";
export const login = (data) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(`login`, {
        email: data.email,
        password: data.password,
        // token: response.token,
      })
      .then(resolve)
      .catch(reject);
  });
};


export const logout = (token) => {
  return new Promise((resolve, reject) => {
    return axios.post(`logout`).then(resolve).catch(reject);
  });
};

export const refreshToken = (token) => {
  return new Promise((resolve, reject) => {
    return axios.delete(`logout`).then(resolve).catch(reject);
  });
};
const service = {
  login,
  logout,
};

export default service;
