import { Suspense } from "react";
import Main from "./Main";
import { Outlet } from "react-router-dom";
import { Preloader } from "./Preloader";
import { TabBar } from "./Tabbar";
import { Header } from "./Header";

const DataSources = () => {
    return (
        <>
            <Header />
            <Main>
                <TabBar />
                <Suspense fallback={<Preloader suspencecallback={true} />}>
                    <Outlet />
                </Suspense>
            </Main>
        </>
    )
}

export { DataSources }