import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../store/reducers/auth";
import { AlertService } from "../../services/alert";
import { useState } from "react";
import { useEffect } from "react";
import { changeThemeApi } from "../../services/profile";
// import { NvaigationBar } from "../navigation/menu";

const MenuItems = [
  {
    title: "Home",
    link: "/home/dashboard",
    match: /home\//gm,
    icon: "fa fa-home",
  },
  {
    title: "Data sources",
    link: "/data-sources/top-domains-queue",
    match: /data-sources\//gm,
    icon: "fa fa-database",
  },
  {
    title: "Video Sources",
    link: "/video-sources/youtube-scrape",
    match: /video-sources\//gm,
    icon: "fa fa-video",
  },
  // {
  //   title: "Profile",
  //   link: "home/dashboard",
  // },
];

function Header() {
  const location = useLocation();
  const [isDark, setTheme] = useState(
    localStorage.getItem("theme") !== null
      ? localStorage.getItem("theme") === "black"
      : true
  );
  const dispatch = useDispatch();
  const __logOut = () => {
    AlertService.confirm({
      message: "Are you sure you want to logout?",
      okText: "Yes",
      cancelText: "No",
    }).then((confirmed) => {
      if (confirmed) dispatch(logout());
    });
  };

  useEffect(() => {
    let previous = localStorage.getItem("theme") === "black"
    const _callApi = async () => {
      changeTheme(isDark);
      if (previous !== isDark) {
        let body = {
          id: "6426d1bf62fa1405a2f8efe9",
          bg_mode: isDark ? "black" : "white",
          action: "edit",
        };
        await changeThemeApi(body)
      }
    }
    _callApi()
    return () => { };
  }, [isDark]);

  // useEffect(() => {
  //   changeTheme(isDark);
  //   return () => {};
  // }, []);

  return (
    <>
      <header>
        <Navbar expand="lg">
          <Container>
            <Navbar.Toggle aria-controls="navbarNav" />
            <Navbar.Collapse id="navbarNav">
              <div className="toggle">
                <label>
                  <input
                    className="toggle-checkbox"
                    type="checkbox"
                    checked={isDark}
                    onClick={(e) => {
                      setTheme(e.target.checked);
                      changeTheme(e.target.checked);
                    }}
                    onChange={e=>{}}
                  ></input>
                  <div className="toggle-slot">
                    <div className="sun-icon-wrapper">
                      <div
                        className="iconify sun-icon"
                        data-icon="feather-sun"
                        data-inline="false"
                      ></div>
                    </div>
                    <div className="toggle-button"></div>
                    <div className="moon-icon-wrapper">
                      <div
                        className="iconify moon-icon"
                        data-icon="feather-moon"
                        data-inline="false"
                      ></div>
                    </div>
                  </div>
                </label>
              </div>
              <Nav className="me-auto justify-content-end w-100">
                <ul className="navbar-nav">
                  {MenuItems.map((menu, index) => {
                    return (
                      <li className="nav-item" key={"header-menu-" + index}>
                        <Link
                          className={
                            location.pathname.match(menu.match)
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={menu.link}
                        >
                          {/* <i className={menu.icon}/> */}
                          {menu.title}
                        </Link>
                      </li>
                    );
                  })}
                  <li
                    className="nav-item"
                    onClick={(sa) => {
                      __logOut();
                    }}
                  >
                    <Link className="nav-link">
                      {/* <i className="fa fa-user-lock"/> */}
                      Logout
                    </Link>
                  </li>
                </ul>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
}
let changeTheme = async (isDark) => {
  // console.log("just called me", isDark);
  if (isDark) {
    localStorage.setItem("theme", "black");
    document.documentElement.setAttribute("data-theme", "dark");
  } else {
    localStorage.setItem("theme", "white");
    document.documentElement.setAttribute("data-theme", "light");
  }
  let body = {
    id: "6426d1bf62fa1405a2f8efe9",
    bg_mode: isDark ? "black" : "white",
    action: "edit",
  };
  await changeThemeApi(body);
};
export { Header };
