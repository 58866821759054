import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/main.css";
import "./assets/styles/custom.css";
import "./assets/styles/index.scss";
import { Routes } from "./routes";
import { Preloader } from "./components/layout/Preloader";
import { Suspense } from "react";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Preloader/>
        {/* <Main> */}
          <Suspense fallback={<Preloader suspencecallback={true} />}>
            <Routes />
          </Suspense>
        {/* </Main> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
