import { Suspense } from "react";

import Main from "./Main";
import { Outlet } from "react-router-dom";
import { Preloader } from "./Preloader";
import { VideoSourceTabBar } from "./VideoSourceTabbar";

import { Header } from "./Header";
const VideoSources = () => {
    return (
        <>
            {/* <AppHeader /> */}
            <Header/>
            <Main>
                <VideoSourceTabBar />
                <Suspense fallback={<Preloader suspencecallback={true} />}>
                    <Outlet />
                </Suspense>
            </Main>
        </>
    )
}

export { VideoSources }