import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { setMessage } from "./message";

import AuthService, { ACCESS_TOKEN } from "../../services/auth.service";

const token = localStorage.getItem(ACCESS_TOKEN);

export const register = createAsyncThunk(
  "auth/register",
  async ({ email, password }, thunkAPI) => {
    try {
      return Promise.resolve({ name: "admin" });
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const data = await AuthService.login({ email, password });
      localStorage.setItem(ACCESS_TOKEN, data.access_token);
      localStorage.setItem("theme", data.data.bg_mode);
      return Promise.resolve({ user: data });
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  try {
    await AuthService.logout();
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem('theme');
    return Promise.resolve({ name: null });
  } catch (e) {
    console.log(e);
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem('theme');
  }
});

const initialState = token
  ? { isLoggedIn: true, user: null }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    });
  },
});

const { reducer } = authSlice;
export default reducer;
