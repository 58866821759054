import { useSelector } from "react-redux";

const ContainerLoader = ({ loading = false }) => {
  if (loading) {

    return (
      <div className="preloader-area relative-container">
        <div className="spinner">
          <div className="inner">
            <div className="disc"></div>
            <div className="disc"></div>
            <div className="disc"></div>
          </div>
        </div>
      </div>
    )
  }
  else return null
}

const Preloader = ({
  suspencecallback
}) => {
  const { loading } = useSelector((state) => {
    return state.loader;
  });
  if (!loading && !suspencecallback) return null;
  return (
    <div className="preloader-area">
      <div className="spinner">
        <div className="inner">
          <div className="disc"></div>
          <div className="disc"></div>
          <div className="disc"></div>
        </div>
      </div>
    </div>
  );
};

export { Preloader, ContainerLoader };
