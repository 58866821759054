import React, { lazy } from "react";
import { ProtectedRoute } from "./protected-routes";
import { useRoutes, Navigate } from "react-router-dom";
import { DataSources } from "../components/layout/Data-Sources";
import { VideoSources } from "../components/layout/VideoSources";
import { Home } from "../components/layout/Home";
// import { Header as AppHeader } from "./components/layout/Header";

const Login = lazy(() => import("../pages/Auth/SignIn"))
const BlankPage = lazy(() => import("../pages/blank-page"))
const Crawlurl = lazy(() => import("../pages/DataSoucePages/crawlurl"))
const Dashboard = lazy(() => import("../pages/Home/dashboard"))
const TaskDetails = lazy(() => import("../pages/Home/task-monitoring"))
const TopDomainQueues = lazy(() => import("../pages/DataSoucePages/top-domain-queue"))
const PendingUrls = lazy(() => import("../pages/DataSoucePages/pending-url"))
const ValidUrls = lazy(() => import("../pages/DataSoucePages/valid-urls"))
const InvalidUrls = lazy(() => import("../pages/DataSoucePages/invalid-urls"))
const EventUpdates = lazy(() => import("../pages/DataSoucePages/event-updates"))
const ComcrawlParser = lazy(() => import("../pages/DataSoucePages/comcrawl-parser"))
const NewsArticles = lazy(() => import("../pages/DataSoucePages/news-articles"))
const OneShotScrape = lazy(() => import("../pages/DataSoucePages/one-shot-scrape"))
const ChannelDetails = lazy(() => import("../pages/VideoSourcePages/channel-details"))
const VideoPendingURL = lazy(() => import("../pages/VideoSourcePages/videos-pending-validation"))
const ValidVideos = lazy(() => import("../pages/VideoSourcePages/valid-videos"))
const InvalidVideoUrls = lazy(() => import("../pages/VideoSourcePages/invalid-videos"))

// const LayoutElement = ({ children }) => {
//   return <section>{children}</section>;
// };

const routes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/home",
    element: <Home />,
    children: [
      {
        path: "dashboard",
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: "job-status",
        element: (
          <ProtectedRoute>
            <TaskDetails />
          </ProtectedRoute>
        ),
      }
    ]
  },
  {
    path: '/data-sources',
    element: <DataSources />,
    children: [
      {
        path: "top-domains-queue",
        element: (
          <ProtectedRoute>
            <TopDomainQueues />
          </ProtectedRoute>
        ),
      },
      {
        path: "crawlurl",
        element: (
          <ProtectedRoute>
            <Crawlurl />
          </ProtectedRoute>
        ),
      },
      {
        path: "urls-pending-validation",
        element: (
          <ProtectedRoute>
            <PendingUrls />
          </ProtectedRoute>
        ),
      },
      {
        path: "valid-events",
        element: (
          <ProtectedRoute>
            <ValidUrls />
          </ProtectedRoute>
        ),
      },
      {
        path: "invalid-urls",
        element: (
          <ProtectedRoute>
            <InvalidUrls />
          </ProtectedRoute>
        ),
      },
      {
        path: "event-updates",
        element: (
          <ProtectedRoute>
            <EventUpdates />
          </ProtectedRoute>
        ),
      },
      {
        path: "comcrawl-parser",
        element: (
          <ProtectedRoute>
            <ComcrawlParser />
          </ProtectedRoute>
        ),
      },
      {
        path: "news-articles",
        element: (
          <ProtectedRoute>
            <NewsArticles />
          </ProtectedRoute>
        ),
      },
      {
        path: "one-shot-scrape",
        element: (
          <ProtectedRoute>
            <OneShotScrape />
          </ProtectedRoute>
        ),
      },
    ]
  },
  {
    path: '/video-sources',
    element: <VideoSources />,
    children: [
      {
        path: "youtube-scrape",
        element: (
          <ProtectedRoute>
            <ChannelDetails />
          </ProtectedRoute>
        ),
      },
      {
        path: "video-pending-val",
        element: (
          <ProtectedRoute>
            <VideoPendingURL />
          </ProtectedRoute>
        ),
      },
      {
        path: "valid-videos",
        element: (
          <ProtectedRoute>
            <ValidVideos />
          </ProtectedRoute>
        ),
      },
      {
        path: "invalid-videos",
        element: (
          <ProtectedRoute>
            <InvalidVideoUrls />
          </ProtectedRoute>
        ),
      },
      {
        path: "blank-page",
        element: (
          <ProtectedRoute>
            <BlankPage />
          </ProtectedRoute>
        ),
      }
    ]
  },
  {
    path: "/*",
    element: <Navigate to="/home/dashboard" replace />,
  },
];

const Routes = () => {
  const __routes = useRoutes(routes);
  return __routes;
};

const SKIP_AUTH_ERROR_PATHS = ["/login", "/regis"];

export { Routes, SKIP_AUTH_ERROR_PATHS };
