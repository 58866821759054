// import { useState, useEffect } from "react";

// import { Link, useLocation } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { Logout } from "../../store/reducers/auth";
// import { NvaigationBar } from "../navigation/menu";
import { useLocation, useNavigate } from "react-router-dom";

const MenuItems = [
  {
    title: "Top Queue",
    key: "top-domains-queue",
    link: "data-sources/top-domains-queue",
  },
  {
    title: "Crawled Domains",
    key: "events-top-domains",
    link: "data-sources/crawlurl",
  },
  {
    title: "Pending urls",
    key: "urls-pending-validation",
    link: "data-sources/urls-pending-validation",
  },
  {
    title: "Valid Events",
    key: "valid-events",
    link: "data-sources/valid-events",
  },
  {
    title: "Invalid Urls",
    key: "invalid-urls",
    link: "data-sources/invalid-urls",
  },
  {
    title: "Event Updates",
    key: "event-updates",
    link: "data-sources/event-updates",
  },
  {
    title: "Comcrawl Parser",
    key: "comcrawl-parser",
    link: "data-sources/comcrawl-parser",
  },
  {
    title: "News Articles",
    key: "news-articles",
    link: "data-sources/news-articles",
  },
  {
    title: "1 Shot scrape",
    key: "one-shot-scrape",
    link: "data-sources/one-shot-scrape",
  },
];
function TabBar() {
  const location = useLocation()
  const navigate = useNavigate();
  return (
    <>
      <ul className="nav nav-tabs" data-bs-tabs="tabs">
        {MenuItems.map((menu)=>{
          return (
            <li className="nav-item" key={menu.key}>
              <button
                className={
                  location.pathname.replace("/", "") === menu.link
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => navigate(`/${menu.link}`)}
              >
                {menu.title}
              </button>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export { TabBar };
