import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import loaderReducer from "./reducers/loader";
// import messageReducer from "./slices/message";

const reducer = {
  auth: authReducer,
  loader: loaderReducer,
  // message: messageReducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
